@import "~antd/dist/antd.css";

.header {
  background: #fff;
  padding-left: 0;
  padding-right: 0;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.login-background {
  background: #fff;
}

.login-layout {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
  background: #fff;
}
